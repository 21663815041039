<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-files"> </i> 档案管理 | 文件夹授权</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-sm-10">
            <span class="sn">请选择档案</span>
            <el-select v-model="archiveId" @change="getList" placeholder="请选择档案">
              <el-option v-for="item in archiveList" :key="item.Key" :value="item.Key" :label="item.Value" />
            </el-select>
          </div>
          <div class="col-md-2">
            <!-- <el-button type="primary" icon="el-icon-back" style="float: right;"
                @click="backPage">返回</el-button> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span class="sn">文件夹授权</span>
            <el-input v-model="teacherName" class="tn" disabled></el-input>
            <el-button @click="getTeacher">用户选择</el-button>
            <el-button @click="copyPower(1)">复制授权(覆盖)</el-button>
            <el-button @click="copyPower(2)">复制授权(叠加)</el-button>
            <el-button @click="clearPower">清空授权</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <el-table :data="tableData" style="width: 100%;margin-top: 10px;margin-bottom: 10px;" row-key="NodeId"
              border default-expand-all :tree-props="{children: 'Children'}">
              <!-- <el-table-column type="selection" width="55"></el-table-column> -->
              <el-table-column prop="NodeName" width="250">
                <template slot-scope="scope">
                  {{ scope.row.NodeName }}
                  <!-- <el-checkbox @change="(val)=>clickAll(val,scope.row)"></el-checkbox> -->
                  <!-- :checked="isChecked(scope.row)" -->
                </template>
              </el-table-column>
              <el-table-column>

                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.Power.all" @change="(val)=>clickAll(val,scope.row)"
                    :checked="isChecked(scope.row)">全选
                  </el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="Power.View" label="查看">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.Power.View" @change="clickView(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="Power.Folder_Add" label="是否允许创建下级文件夹">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.Power.Folder_Add" @change="clickCell(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="Power.Folder_Edit" label="修改文件夹">
                  <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.Power.Folder_Edit"
                          @change="clickCell(scope.row)"></el-checkbox>
                  </template>
              </el-table-column> -->
              <el-table-column prop="Power.Folder_Del" label="是否允许删除下级文件夹">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.Power.Folder_Del" @change="clickCell(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="Power.File_Add" label="添加文件">
                  <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.Power.File_Add"
                          @change="clickCell(scope.row)"></el-checkbox>
                  </template>
              </el-table-column>
              <el-table-column prop="Power.File_Edit" label="修改文件">
                  <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.Power.File_Edit"
                          @change="clickCell(scope.row)"></el-checkbox>
                  </template>
              </el-table-column>
              <el-table-column prop="Power.File_Del" label="删除文件">
                  <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.Power.File_Del"
                          @change="clickCell(scope.row)"></el-checkbox>
                  </template>
              </el-table-column> -->
              <el-table-column prop="Power.File_Down" label="是否允许下载文件">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.Power.File_Down" @change="clickCell(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="save()">保存</el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <rTeacher ref="radio_teacher" @on-saved="setTeacher" />
      <cTeacher ref="checkbox_teacher" @on-saved="setTeachers" />
    </div>
  </div>
</template>
<script>
  import rTeacher from "./rteacher";
  import cTeacher from "./cteacher";

  export default {
    name: "index",
    components: { rTeacher, cTeacher },
    data() {
      return {
        archiveId: "",
        archiveList: [],
        loading: false,
        tableData: [],
        teacherId: 0,
        teacherName: "",
        // role: "",
        copyType: 0,
        check: false,
      }
    },
    created() {
      let user = this.whale.user.get()
      this.teacherId = user.ID
      this.teacherName = user.NAME
      // this.role = user.ROLE
      // if (this.archive.ID == undefined)
      //     this.backPage()
      this.getArchives()
      // this.getList();
    },
    watch: {
      tableData(n) {
        console.log(n)
      }
    },
    methods: {
      getList() {
        let self = this;
        this.loading = true;
        this.whale.remote.getResult({
          url: "/api/School/DOC/PowerApi/GetTableTree",
          data: {
            teacherId: this.teacherId,
            archiveId: this.archiveId
          },
          completed: function (res) {
            // console.log(res.DATA)
            self.tableData = res.DATA.TreeNodes;
            self.loading = false;
          }
        })
      },
      getArchives() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/DOC/ArchiveApi/GetArchives",
          completed: function (its) {
            // console.log(its.DATA)
            self.archiveList = its.DATA;
            self.archiveId = its.DATA[0].Key
            self.getList();
          }
        })
      },
      clickCell(item) {
        if (!item.Power.View)
          item.Power.View = true;
        this.isChecked(item);
        console.log('clickCell', this.tableData)
      },
      clickView(item) {
        let self = this;
        if (!item.Power.View) {
          self.clickAll(false, item);
        }
        console.log('clickView', this.tableData)
      },
      clickAll(val, item) {
        item.Power.View = val;
        item.Power.Folder_Add = val;
        item.Power.Folder_Del = val;
        item.Power.File_Down = val;
        if (item.Children && item.Children.length > 0) {
          item.Children.forEach(child => {
            this.clickAll(val, child)
          })
        }
        console.log('clickAll', this.tableData)
      },
      isChecked(item) {
        item.Power.all = item.Power.View && item.Power.Folder_Add && item.Power.Folder_Del && item.Power.File_Down
        return item.Power.all;
      },
      save() {
        let self = this;
        var datas = self.flattenTree(self.tableData)
        self.whale.remote.getResult({
          url: "/api/School/DOC/PowerApi/UpdatePower",
          data: {
            teacherId: self.teacherId,
            archiveId: self.archiveId,
            treeNodes: JSON.stringify(datas)
          },
          completed: function (res) {
            if (res.CODE == 0)
              self.whale.toast.info("更新成功")
            else self.whale.toast.err(res.MESSAGE)
          }
        })
      },
      flattenTree(treeData) {
        let result = [];

        function recursiveFlatten(node) {
          result.push({
            nodeId: node.NodeId,
            nodeName: node.NodeName,
            power: node.Power
          });
          if (node.Children.length > 0) {
            for (const child of node.Children) {
              recursiveFlatten(child);
            }
          }
        }

        treeData.forEach(rootNode => {
          recursiveFlatten(rootNode);
        });
        return result;
      },
      backPage() {
        this.$router.push({ name: 'doc-archive' })
        // this.$router.go(-1);
      },
      getTeacher() {
        this.$refs.radio_teacher.init();
      },
      setTeacher(item) {
        this.teacherId = item.Id;
        this.teacherName = item.Name;
        this.getList()
      },
      clearPower() {
        let self = this;

        function cancelChoose(node) {
          self.clickAll(false, node);
          if (node.Children.length > 0) {
            for (const child of node.Children) {
              cancelChoose(child);
            }
          }
        }

        self.tableData.forEach(rootNode => {
          cancelChoose(rootNode);
        });
      },
      copyPower(type) {
        this.copyType = type
        // console.log(type)
        this.$refs.checkbox_teacher.init();
      },
      setTeachers(items) {
        // console.log('items:' + items.length)
        if (items.length > 0) {
          let self = this;
          self.whale.remote.getResult({
            url: "/api/School/DOC/PowerApi/CopyPower",
            data: {
              teacherId: self.teacherId,
              archiveId: self.archiveId,
              userIds: JSON.stringify(items),
              type: self.copyType
            },
            completed: function () {
              self.whale.toast.info("更新成功")
            }
          })
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .sn {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }

  .tn {
    width: 120px;
    margin-right: 10px;
  }

  ::v-deep .el-input.is-disabled .el-input__inner {
    font-weight: bold;
    color: #7d7d7d;
  }
</style>